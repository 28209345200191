<template>
  <WidgetContainer width="4" header="Recent Import History">
    <template v-slot:header>
      <small class="text-font-secondary">
        Last 48 hours
      </small>
    </template>
    <template v-slot>
      <DataTableSimple
        table-key="AdvertiserInventorySourcesImportHistoryWidget_DataTable"
        row-key="id"
        :columns="dataTableColumns"
        :rows="dataTableRows"
        :loading="loading"
        :pagination="{
          sortBy: 'time_completed',
          descending: true,
          page: 1,
          rowsPerPage: 5
        }"
      />
    </template>
  </WidgetContainer>
</template>

<script>
import DataTableSimple from "@/components/UI/DataTableSimple";
import WidgetContainer from "@/components/UI/Widgets/WidgetContainer";
import StatusBadge from "@/components/UI/StatusBadge";

export default {
  name: "AdvertiserInventorySourcesImportHistoryWidget",
  components: { WidgetContainer, DataTableSimple },
  props: {},
  data() {
    return {
      loading: false,
      startDate: this.$dayjs().tz()
        .subtract(48, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD 23:59:59"),
      dataTableRows: [],
      dataTableColumns: [
        {
          name: "source",
          label: "Inventory Source",
          field: row =>
            "<p class='ellipsis'>" +
            row.inventory_source_name +
            "</p>" +
            "<p class='--subtext'>" +
            row.inventory_source_id +
            "</p>",
          style: "max-width: 10vw; min-width: 140px"
        },
        {
          name: "time_completed",
          label: "Completed",
          hideField: true,
          isComponentField: true,
          field: row => ({
            component: StatusBadge,
            componentProperties: {
              status: row.failed == 1 ? "error" : "success",
              iconOnly: true,
              hideTooltip: true,
              customAppendHtml:
                "<small class='text-subtle'>" +
                (!row.time_completed
                  ? ""
                  : this.convertUtcToBusinessTimezone(
                      row.time_completed,
                      "fromNow"
                    )) +
                "</small>"
            },
            componentBody: ""
          }),
          sort: (a, b, rowA, rowB) =>
            this.sortDate(rowA.time_completed, rowB.time_completed)
        },
        {
          name: "products_active_total",
          label: this.$store.state.productTypeConfigs.typeFormatted + "s",
          field: "products_active_total",
          format: val => this.numberFormatter(val),
          sort: (a, b) => this.sortInteger(a, b)
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.populateWidget();
  },
  methods: {
    populateWidget() {
      this.loading = true;

      this.$store
        .dispatch("inventorySources/GET_IMPORT_HISTORY_REQUEST", {
          params: {
            start_date: this.startDate,
            end_date: this.endDate
          }
        })
        .then(data => {
          this.dataTableRows = data;
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
